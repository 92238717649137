import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/training"

const SchulungenPage = () => (
  <Layout>
    <Seo title="البرامج التدريبية" />
    <Hero />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <StaticImage
              src="../images/training.jpg"
              quality={65}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Clavicon Profile"
              placeholder="tracedSVG"
            />
          </div>
          <div className="column is-three-fifths">
            <div className="content">
              <h3 className="mt-0">
              الوقاية من خلال المعرفة – برامج تدريبية وتدابير توعوية كجزء من مفهوم المنظومة الأمنية الشاملة
              </h3>
              <p>
              نقدم لك برامج تدريبية وتدابير توعوية حول المسائل الأمنية في مختلف التخصصات. فلك أن تغتنم فرصة الإفادة من تخصصنا وخبرتنا التقنية التي اكتسبناها على مدى أعوام طويلة. ويُسعدنا أن نشاركك هذه المعرفة.
              </p>
              <p>برامجنا التدريبية القياسية تشمل مواضيع مثل:</p>
              <ul>
                <li>حماية المعلومات .</li>
                <li>إدارة الطوارئ والأزمات.</li>
                <li>
                تدريبات للأشخاص المُهددين بالخطروعائلاتهم.
                </li>
              </ul>
              <p>
              يمكننا القيام بالدورات التدريبية الخاصة مباشرة لديك أو في أي مكان مناسب. وبجانب برامجنا التدريبية القياسية يُسعدنا أن نطور من أجلك منظومات تدريبية تتوافق مع احتياجاتك.
              </p>
              <p>
              للحصول على عرض شخصي يُمكنك في أي وقت التواصل معنا في مقابلة شخصية.
              </p>
              <h4>عرض التدريب الحالي</h4>
              <Link to="/social-engineering">Social Engineering</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SchulungenPage
